import { Box, Container, Typography, makeStyles } from '@material-ui/core';

import React from 'react';
import image0 from './media/aboutMe.jpg';
import { useMediaQuery } from 'react-responsive';

const useStyles = makeStyles((theme) => ({
  aboutImg: {
    width: '460px',
    height: '400px',
    marginRight: '100px'
  },
  aboutImgMobile: {
    width: '345x',
    height: '300px',
  },
  hr: {
    width: '10%',
    height: '5px',
    marginLeft: '5',

  }
}));

export function AboutMe() {
  const classes = useStyles();

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 1224px)'
  })

  return (
    <div>
      {isDesktopOrLaptop && <>
        <Container>
          {/* Title */}
          <Box paddingTop="50px" marginTop="50px" marginBottom="100px" justifyContent="center">
            <h1> <strong>─── Here's a little about me... ───</strong> </h1>
          </Box>

          {/* Horizontal box with image and description */}
          <Box display="flex" flexDirection="row" p={1} m={5}>
            <img
              className={classes.aboutImg}
              src={image0}
              alt="Prashanth Pai"
              key="aboutMeImage" />

            <Typography variant="h6">
            My name is Prashanth Pai, and I am a senior at Rice University studying Computer Science with a minor in Poverty, Justice, and Human Capabilities.
              I was born and raised in Houston, Texas and will always consider that to be home. My goal as a computer scientist is to make a human impact through technology,
              and I've found the interdisciplinary nature of CS to be very helpful with accomplishing this.
              I hope that my minor can give me a broader understanding of real world issues so I can incorporate ethics into my career.
              I'm currently working as a teaching assistant at Rice to help graduate students develop programming and data science skills.
              I've also gotten involved with research and am currently working as an intern at Lawrence Berkeley National Laboratory to optimize parallel operations on 
              distributed data sets.
            </Typography>

          </Box>

        </Container>
      </>}
      {isTabletOrMobileDevice && <>
        <Box justifyContent="center">
          {/* Title */}
          <Box paddingTop="50px" marginTop="50px" marginBottom="50px">
            <h1> <strong>Here's a little about me...</strong> </h1>
          </Box>

          <img
              className={classes.aboutImgMobile}
              src={image0}
              alt="Prashanth Pai"
              key="aboutMeImage" />
          
          <Typography variant="h6" align="center" style={{marginTop: "50px", paddingLeft: "25px", paddingRight: "25px", marginBottom: "100px"}}>
              My name is Prashanth Pai, and I am a Senior at Rice University studying Computer Science with a minor in Poverty, Justice, and Human Capabilities.
              I was born and raised in Houston, Texas and will always consider that to be home. My goal as a computer scientist is to make a human impact through technology,
              and I've found the interdisciplinary nature of CS to be very helpful with accomplishing this.
              I hope that my minor can give me a broader understanding of real world issues so I can incorporate ethics into my career.
              I'm currently working as a teaching assistant at Rice to help graduate students develop programming and data science skills.
              I've also gotten involved with research and am currently working as an intern at Lawrence Berkeley National Laboratory to optimize parallel operations on 
              distributed data sets.
              </Typography>

        </Box>
      </>}
    </div>
  );
}
