import React from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import EmailIcon from '@material-ui/icons/Email';
import { useMediaQuery } from 'react-responsive';
import './Contact.css';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '25px'
  },
}));

export function Contact() {
  const classes = useStyles();

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 1224px)'
  })

  return (
    <Container>
      {/* Title */}
      {isDesktopOrLaptop &&
        <Box paddingTop="50px" marginTop="50px" marginBottom="50px">
          <h1> <strong>I'm always looking to <em>connect</em>...</strong> </h1>
        </Box>
      }

      {isTabletOrMobileDevice &&
        <Box paddingTop="50px" marginTop="50px" marginBottom="50px">
          <h1> <strong>I'm always looking to <em>connect</em>...</strong> </h1>
        </Box>
      }

      <p>Please feel free to reach out to me through email, Facebook, LinkedIn, or GitHub! </p>

      {/* Project grid */}
      <div className={classes.root}></div>
      <Grid container spacing={0}>
        <Grid item xs={6} sm={3} md={3} lg={3} key='Email'>
          <a className="anchorContact" color="black" href="mailto:pbp2@rice.edu" target="_blank" rel="noopener noreferrer">
            <EmailIcon style={{ fontSize: 80 }}></EmailIcon>
          </a>
        </Grid>
        <Grid item xs={6} sm={3} md={3} lg={3} key='Facebook'>
          <a href="https://www.facebook.com/prashanth.pai.31/" target="_blank" rel="noopener noreferrer">
            <FacebookIcon color="primary" style={{ fontSize: 80 }}></FacebookIcon>
          </a>
        </Grid>
        <Grid item xs={6} sm={3} md={3} lg={3} key='GitHub'>
          <a href="https://github.com/prashanth314" target="_blank" rel="noopener noreferrer">
            <GitHubIcon style={{ fontSize: 80 }}></GitHubIcon>
          </a>
        </Grid>
        <Grid item xs={6} sm={3} md={3} lg={3} key='LinkedIn'>
          <a href="https://www.linkedin.com/in/prashanth-pai-5a7ab0170/" target="_blank" rel="noopener noreferrer">
            <LinkedInIcon color="primary" style={{ fontSize: 80 }}></LinkedInIcon>
          </a>
        </Grid>
      </Grid>
    </Container>
  );
}
