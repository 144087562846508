import c1 from './media/c1.jpeg';
import ford from './media/ford.jpg';
import hcss from './media/hcss.jpg';
import lbnl from './media/lbnl.jpeg';
import rice from './media/rice.jpg';
import walmartTech from './media/walmartTech.jpeg';

const experienceData = [
    {
        title: 'Capital One Software Engineer Intern',
        location: 'Richmond, Virginia & Plano, Texas',
        date: "June 2021 - August 2021",
        did: "This past summer, I had the opportunity to ensure that Capital One customers received their cards as efficiently as possible " + 
        "by leveraging AWS technologies such as Lambda, SQS, DocumentDB, Aurora, and SNS. Through automating key infrastructure maintenance jobs, " + 
        "I helped save our developers nearly one month of dev time annually. I even gained experience with launching highly available databases and running database migrations with DMS.",
        learned: "The learning resources at Capital One allowed me to learn more about how powerful AWS is. I ended up earning my " +
        "Cloud Practioner Certification towards the end of the summer and can't wait keep learning more about the cloud.",
        liked: "I really enjoyed my interactions with other interns. I worked on a pod of 4 interns and also got to network with others through trivia, painting, and cooking events!",
        img: c1,
        imgTitle: 'Capital One',
        imgWidth: '33%',
    },
    {
        title: 'Rice University Teaching Assistant',
        location: 'Houston, Texas',
        date: "January 2020 - Present",
        did: "Last year, I helped put together a new Master's course for graudate CS students at Rice and helped teach the class to Chevron engineers and Master's students. " +
        "Along the way, I worked with two Rice professors, Dr. Zoran Budimlic and Dr. John Greiner, and the Rice Chair of Computer Science, Dr. Chris Jermaine, to " +
        "put together a Wikipedia query engine from scratch. I'm returning to be a TA this year and will also be helping out with a course in " +
        "Poverty, Justice, and Human Capabilities alongside the director of the PJHC department, Dr. Diana Strassmann. In the past, I've helped teach " + 
        "a different PJHC course and a computer engineering lab.",
        learned: "I think one skill people often overlook is the ability to teach. You can only teach a concept if you truly understand the core fundamentals of that subject, so being a TA has helped me think about old topics in new ways." ,
        liked: "I really enjoy being a part of that light bulb moment, when someone understands a new idea.",
        img: rice,
        imgTitle: 'Rice',
        imgWidth: '33%',
    },
    {
        title: 'Lawrence Berkeley National Lab Research Intern',
        location: 'Berkeley, California & Houston, Texas',
        date: "January 2021 - Present",
        did: "I am currently working on a joint project between Rice University and Lawrence Berkeley National Laboratory alongside my mentors " +
        "Dr. Zoran Budimlic and Dr. Costin Iancu. We're working to optimize Arkouda, an " +
        "interactive Num-py like library which utilizes Chapel, a popular parallel programming language, as a backend. I'm mainly working on improving the " + 
        "Python client side of the framework. Recently, I presented my research to scientists at the Berkeley Lab and U.S. Department of Energy during a talk on “Dynamic Runtime "+
        "Fusion of Parallel Operators for Graph Analytics and Machine Learning” and look foward to publishing a paper soon!",
        learned: "Conducting research has taught me to be more comfortable with the unknown. Answers to research problems aren't simply on Stack Overflow, and that has motivated me to be intellectually curious. " ,
        liked: "It's awesome to contribute to science and help develop technology which could eventually revolutionize how we process distributed data sets in processes for ML and AI. ",
        img: lbnl,
        imgTitle: 'Lawrence Berkeley National Lab',
        imgWidth: '33%',
    },
    {
        title: 'Ford Motor Company Software Engineer Intern',
        location: 'Dearborn, Michigan (Remote)',
        date: "June 2020 - Aug. 2020",
        did: "Last summer, I worked at Ford Motor Company as a Software Engineer Intern. " +
            "Specifically, I worked on a data input tool for Manufacturing Vehicle Logisitcs, collaborating internationally with the Global Tracking and Trace team.",
        learned: "I had the great opportunity to learn more about Springboot, Thymeleaf, Alteryx, and PCF and improved my skills in Java, JavaScript, and SQL." +
                " I also learned so much about the car industry and explored the data behind connected vehicles!",
        liked: "My favorite part of my internship was the IT Innovation Challenge. I met three other interns, and we walked through the design process to develop a prototype" +
        " for a WebEx analytics tool which we presented to Ford executives.",
        img: ford,
        imgTitle: 'Ford',
        imgWidth: '33%',
    },
    {
        title: 'Heavy Construction Software Specialists Software Development Intern',
        location: 'Sugar Land, Texas',
        date: "May 2019 - August 2019",
        did: "Two summers ago, I worked as a Software Development Intern at HCSS where I developed Android applications for fueling and construction workers." +
            " Specifically, I developed a module that allows construction workers to log safety observations offline, helping prevent the Fatal 4 of construction: Falls, Struck-By, Caught-In/Between, and Electrocutions. ",
        learned: "This was my first internship, so I definitely learned a lot about industry level tech development. Specifically, I learned about Agile development, GitHub, and how to work on a team. I also learned some" +
            " really cool app development skills and was introduced to reactive data models and databases.",
        liked: "I loved being able to code something and immediately being able to see the results on a phone screen. In terms of HCSS, I really enjoyed being a part of the mentorship and development programs and being active in some of the fitness events that the company held.",
        img: hcss,
        imgTitle: 'HCSS',
        imgWidth: '33%',
    },
    {
        title: 'Walmart Technology Data Science Extern',
        location: 'Austin, Texas',
        date: "March 2019 (Spring Break)",
        did: "During Spring Break of my freshman year of college, I had the opportunity to work for Walmart Technology as a data science extern. I specifically worked on generating training sets for a natual language processing engine which would help out with customer transactions. ",
        learned: "I faced a steep learning curve during this externship as it was my first real technical role. However, I learned the importance of planning ahead during software development and took a deep dive into coreference analysis." ,
        liked: "I really enjoyed working with predictive technology. This allowed me to directly interact with AI and NLP for the first time.",
        img: walmartTech,
        imgTitle: 'Walmart Technology',
        imgWidth: '33%',
    },
]

export default experienceData;
