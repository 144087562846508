import { Box, Container } from '@material-ui/core';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import FavoriteIcon from '@material-ui/icons/Favorite';
import HelpIcon from '@material-ui/icons/Help';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import Slide from '@material-ui/core/Slide';
import TodayIcon from '@material-ui/icons/Today';
import Typography from '@material-ui/core/Typography';
import WorkIcon from '@material-ui/icons/Work';
import experienceData from './experienceData';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import { useMediaQuery } from 'react-responsive';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
    marginTop: '25px'
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  blackWhite: {
    color: theme.palette.grey[100],
    backgroundColor: theme.palette.grey[900],
  },
  blackBlue: {
    color: lightBlue[300],
    backgroundColor: theme.palette.grey[900],
  },
  blackRed: {
    color: red[700],
    backgroundColor: theme.palette.grey[900],
  },
  bigDiv: {
    width: '100%',
    backgroundColor: "#F3F3F3"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export function Experience() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 1224px)'
  })

  return (
    <div className={classes.bigDiv}>


      <Container>
        {/* Title */}
        {isDesktopOrLaptop &&
          <Box paddingTop="50px" marginTop="50px" marginBottom="125px">
            <h1> <strong>─── My experiences at ... ───</strong> </h1>
          </Box>
        }

        {isTabletOrMobileDevice &&
          <Box paddingTop="50px" marginTop="50px" marginBottom="75px">
            <h1> <strong>My experiences at ...</strong> </h1>
          </Box>
        }

        {/* Each experience button */}
        <div className={classes.root}>
          {[...Array(experienceData.length).keys()].map((index) => (
            <ButtonBase
              focusRipple
              key={experienceData[index].imgTitle}
              className={classes.image}
              focusVisibleClassName={classes.focusVisible}
              onClick={(event) => {
                setIndex(index);
                handleClickOpen();
              }}
              style={{
                width: experienceData[index].imgWidth,
              }}
            >
              <span
                className={classes.imageSrc}
                style={{
                  backgroundImage: `url(${experienceData[index].img})`,
                }}
              />
              <span className={classes.imageBackdrop} />
              <span className={classes.imageButton}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="inherit"
                  className={classes.imageTitle}
                >
                  {experienceData[index].imgTitle}
                  <span className={classes.imageMarked} />
                </Typography>
              </span>
            </ButtonBase>
          ))}
        </div>

        {isDesktopOrLaptop &&
          <Box paddingTop="50px" marginTop="50px" paddingBottom="50px" marginBottom="50px">
            <h1> <strong>─── have helped me <em>grow</em>. ───</strong> </h1>
          </Box>
        }

        {isTabletOrMobileDevice &&
          <Box paddingTop="25px" marginTop="50px" paddingBottom="50px" marginBottom="50px">
            <h1> <strong>have helped me <em>grow</em>.</strong> </h1>
          </Box>
        }

        {/* Each dialog box */}
        <Dialog onClose={handleClose} TransitionComponent={Transition} aria-labelledby="customized-dialog-title" open={open}>
          {/* Top section */}
          <MuiDialogTitle >
            {/* Work icon and name */}
            <Box display="flex" flexDirection="row">
              <Box paddingTop={.5} marginRight={3.75} marginLeft={1.5}>
                <WorkIcon></WorkIcon>
              </Box>
              <Typography variant="h5">
                {experienceData[index].title}
              </Typography>
            </Box>
            {/* Location icon and location */}
            <Box display="flex" flexDirection="row">
              <Box paddingTop={.5} marginRight={3.75} marginLeft={1.5}>
                <LocationOnIcon></LocationOnIcon>
              </Box>
              <Typography variant="h6">
                {experienceData[index].location}
              </Typography>
            </Box>
            {/* Date icon and date */}
            <Box display="flex" flexDirection="row">
              <Box paddingTop={.5} marginRight={3.75} marginLeft={1.5}>
                <TodayIcon></TodayIcon>
              </Box>
              <Typography variant="h6">
                {experienceData[index].date}
              </Typography>
            </Box>
          </MuiDialogTitle>
          <DialogContent dividers>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar className={classes.blackWhite}>
                    <HelpIcon />
                  </Avatar>
                </ListItemAvatar>
                {(index > 2 || index === 0) ? <ListItemText primary="What I did" secondary={experienceData[index].did} /> : <ListItemText primary="What I'm doing" secondary={experienceData[index].did} />}
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemAvatar>
                  <Avatar className={classes.blackBlue}>
                    <AccountCircleIcon />
                  </Avatar>
                </ListItemAvatar>
                {(index > 2 || index === 0) ? <ListItemText primary="What I learned" secondary={experienceData[index].learned} /> : <ListItemText primary="What I'm learning" secondary={experienceData[index].learned} />}
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemAvatar>
                  <Avatar className={classes.blackRed}>
                    <FavoriteIcon />
                  </Avatar>
                </ListItemAvatar>
                {(index > 2 || index === 0) ? <ListItemText primary="What I liked" secondary={experienceData[index].liked} /> : <ListItemText primary="What I'm liking" secondary={experienceData[index].liked} />}
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Continue
          </Button>
          </DialogActions>
        </Dialog>

      </Container>
    </div>
  );
}
