import FacebookIcon from '@material-ui/icons/Facebook';
/* Import icons */
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LanguageIcon from '@material-ui/icons/Language';
import React from 'react';
import ReceiptIcon from '@material-ui/icons/Receipt';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import bot0 from './media/bot0.jpg';
import bot1 from './media/bot1.jpg';
import india from './media/india.png';
import kempner0 from './media/kempner0.png';
import kempner1 from './media/kempner1.png';
import kempner2 from './media/kempner2.png';
import kempner3 from './media/kempner3.png';
import kempner4 from './media/kempner4.png';
import kempner5 from './media/kempner5.png';
import mali from './media/mali.png';
/* Import images */
import project0 from './media/project0.png';
import project0Alt from './media/project0Alt.png';
import project1 from './media/project1.png';
import project1Alt from './media/project1Alt.png';
import project2 from './media/project2.png';
import project2Alt from './media/project2Alt.png';
import project3 from './media/project3.png';
import project3Alt from './media/project3Alt.png';
import project4 from './media/project4.png';
import project4Alt from './media/project4Alt.png';
import project5 from './media/project5.png';
import project5Alt from './media/project5Alt.png';

/* Map to data */
const projectTiles = [
    {
        img: project0,
        imgAlt: project0Alt,
        title: 'Interlocal',
        altKey: 'Interlocal Alt',
        icon: <LanguageIcon></LanguageIcon>,
        date: "Jan. 2021 - May 2021",
        details: "Interlocal is an application which international students can use to ask questions about Houston and build a community for a seamless transition to Rice. " +
        "This application was a product of a senior design course which I took during my junior year of college in which my class worked as a tech startup for our customer, Schlumberger. " +
        "We split up into frontend, middleware, and backend teams and handed over our design documentation to Schlumberger at the end of the semester. They plan on using the design to build a platform for their international workers. ",
        lessons: "Working in a class startup taught me the importance of balancing realistic coding expectations with ambitious design goals. Additionally, from a technical standpoint, I had the opportunity to work on " + 
        "the frontend team and implement a React-Redux architecture.",
        additionalDetails: "Feel free to check out our application below!",
        additionalImages: []
    },
    {
        img: project1,
        imgAlt: project1Alt,
        title: 'Wikipedia',
        altKey: 'Wikipedia Alt',
        icon: <LanguageIcon></LanguageIcon>,
        date: "August 2019 - May 2020",
        details: "As a data science teaching assistant, I had the rare opportunity to implement a Wikpedia query engine from scratch. The ideas are simple: download a Wikipedia dump, represent each article as a vector, peform PCA on the vectors to project them into a new vector space, " +
        "represent the articles as a graph, and run page rank on that graph. Next, when someone types in a search, that search string can become a vector, we can find the corresponding vectors which are closest to that search vector, and we can order the closest vectors (articles) by their page rank. " +
        "This query engine became the driving foundation of an intro data science course I help teach to graduate students. Additionally, as a Poverty, Justice, and Human Capabilities minor, I've had the unique opportunity to become a WikiEducation Editor and develop two articles, 'Education in Mali' and 'Debt Bondage in India.'" +
            " I spent two semesters conducting research about these two topics and added over 75,000 characters to these articles based on over 40 sources. I've also served as a Wikipedia campus ambassador for two classes, co-organized a Wikpedia Edit-a-thon, and am working to co-organize another edit-a-thon this fall.",
        lessons: "We're often taught that Wikipedia is a bad resource to use but fail to realize that Wikipedia articles are usually the top pages that show up from a Google search." +
            " I learned a lot about the accessibility of education and how it's essential to spread knowledge if you are able to. I also learned a lot about Mali and India. ",
        additionalDetails: "Click on the images below to check out my articles!",
        additionalImages: [mali, india]
    },
    {
        img: project2,
        imgAlt: project2Alt,
        title: 'Me.nu',
        altKey: 'Me.nu Alt',
        icon: <FastfoodIcon></FastfoodIcon>,
        date: "May 2020 - Present",
        details: "Me.nu is a nonprofit dining preference startup that strives to connect our application's users with their most preferred restaurants. " +
            "Our team is comprised of about 13 Rice students, and I'm mainly working with the frontend and mobile teams. However, like most startups, our roles are pretty fluid, so I've also worked a bit with the backend and marketing teams. ",
        lessons: "The most useful skill I've learned from working on the frontend team is how to use React.js. That's actually what inspired me to create this website. Additionally, we've all learned how to create applications from scratch, delegate work, and work as a team.",
        additionalDetails: "Although our app is no longer up due to a lack of funding, check out some of our landing images below.",
        additionalImages: []
    },
    {
        img: project3,
        imgAlt: project3Alt,
        title: 'Bill.com',
        altKey: 'Bill.com Alt',
        icon: <ReceiptIcon></ReceiptIcon>,
        date: "September 2020",
        details: " During my second Hackathon at Rice, my team and I placed third in Bill.com's API challenge for developing an iOS app that made customer-vendor transactions more efficient." +
            " Specifically, we displayed useful information in QR codes which reduces the amount of information users need to type in when completing a transaction.",
        lessons: " I learned about iOS development, how to implement an API, and how to use Postman to test out API's.",
        additionalDetails: "",
        additionalImages: []
    },
    {
        img: project4,
        imgAlt: project4Alt,
        title: 'Eat Rice Bot',
        altKey: 'Eat Rice Bot Alt',
        icon: <FacebookIcon></FacebookIcon>,
        date: "September 2019",
        details: " During my first Hackathon at Rice, I was on a team that placed third overall for developing a Facebook Messenger bot that helps Rice students " +
            "find dining options on campus based on their location, preferences, and food restrictions.",
        lessons: " This was my first experience working with Github and participating in a Hackathon, so I learned a lot about development and teamwork. Additionally, I used Wit.ai to train" +
            " our bot, so being introduced to ML and artifical intelligence was pretty cool.",
        additionalDetails: "Check out some of the conversations I've had with this bot below!",
        additionalImages: [bot0, bot1]
    },
    {
        img: project5,
        imgAlt: project5Alt,
        title: 'Keep Kempner Clean',
        altKey: 'Keep Kempner Clean Alt',
        icon: <WbSunnyIcon></WbSunnyIcon>,
        date: "June 2016 - May 2018",
        details: "Keep Kempner Clean is a litter prevention app that allows student organizations to 'adopt' certain outdoor sections of Kempner High School to clean and beautify. " +
            "I also used this app as a way to spread useful safety information when Hurricane Harvey hit Houston. This was my first real tech project and solidified my interest in CS. ",
        lessons: "I learned a lot about how to publish an app and all of the non-technical aspects of an app's release, such as advertising. Additionally, I had the chance to talk about " +
            "my app at the Houston Makers Faire and Keep Texas Beautiful state conference, so I developed public speaking skills and met a lot of cool people.",
        additionalDetails: "Although this app is no longer published on the AppStore and GooglePlay Store, check out some of the images below!",
        additionalImages: [kempner0, kempner1, kempner2, kempner3, kempner4, kempner5]
    }
]

export default projectTiles;
