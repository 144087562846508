import React from 'react';
import './App.css';
import { AboutMe } from './pages/AboutMe/AboutMe';
import { Experience } from './pages/Experience/Experience';
import { Projects } from './pages/Projects/Projects';
import { Interests } from './pages/Interests/Interests';
import { Contact } from './pages/Contact/Contact';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { Link, animateScroll as scroll } from "react-scroll";
import HomeIcon from '@material-ui/icons/Home';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  personButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiToolbar: {
      // Name of the rule
      regular: {
        // Some CSS
        color: 'white',
        backgroundColor: 'black',
        borderBottomColor: 'red'
      },
    },
  },
});

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function App(props) {
  const classes = useStyles();

  const scrollToTop = () => {
    scroll.scrollToTop();
};  

  return (
    <div className="App">
      
      {/* Stick navigation bar */}
      <Container maxWidth="sm">
        <ElevationScroll {...props}>
          <AppBar>
          <ThemeProvider theme={theme}>
              <Toolbar>
              
                <div>
                  <IconButton aria-label="home">
                    <HomeIcon style={{ color: '#ffffff' }} onClick={scrollToTop}></HomeIcon>
                  </IconButton>  
                </div>
                
                <Typography variant="h6" className={classes.title}>
                  <div className="nav-item" >
                      <Link 
                            activeClass="active"
                            to="aboutMe"
                            spy={true}
                            smooth={true}
                            offset={-25}
                            duration={500}
                      >
                        <a className="anchor" href="#aboutMe">
                          About
                        </a>
                      </Link>
                  </div>
                </Typography>

                <Typography variant="h6" className={classes.title}>
                  <div className="nav-item">
                    <Link activeClass="active"
                            to="experience"
                            spy={true}
                            smooth={true}
                            offset={-60}
                            duration={500}
                      >
                      <a className="anchor" href="#experience">
                        Experience
                      </a>
                    </Link>
                  </div>
                </Typography>

                <Typography variant="h6" className={classes.title}>
                  <div className="nav-item">
                    <Link activeClass="active"
                              to="projects"
                              spy={true}
                              smooth={true}
                              offset={-60}
                              duration={500}
                        >
                      <a className="anchor" href="#projects">
                        Projects
                      </a>
                    </Link>
                  </div>  
                </Typography>

                <Typography variant="h6" className={classes.title}>
                  <div className="nav-item">  
                    <Link activeClass="active"
                              to="interests"
                              spy={true}
                              smooth={true}
                              offset={-60}
                              duration={500}
                        >
                        <a className="anchor" href="#interests">
                          Interests
                        </a>  
                    </Link>
                  </div>  
                </Typography>

                <Typography variant="h6" className={classes.title}>
                  <div className="nav-item">  
                    <Link activeClass="active"
                              to="contact"
                              spy={true}
                              smooth={true}
                              offset={-60}
                              duration={500}
                        >
                        <a className="anchor" href="#contact">
                          Contact
                        </a>  
                    </Link>
                  </div>  
                </Typography>

              </Toolbar>
            </ThemeProvider>
          </AppBar>
        </ElevationScroll>
      </Container>

      <div id="aboutMe">
        <AboutMe />
      </div>
      <div id="experience">
        <Experience />
      </div>
      <div id="projects">
        <Projects />
      </div>
      <div id="interests">
        <Interests />
      </div>
      <div id="contact">
        <Contact />
      </div>
    </div>
  );
}

export default App;
