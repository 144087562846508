import React, { useState } from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import interestsData from './interestsData';
import "./Interests.css";
import { useTransition, animated } from 'react-spring';
import { useMediaQuery } from 'react-responsive';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    bigDiv: {
        width: '100%',
        height: '800px',
        backgroundColor: "#F3F3F3"
    },
    smallDiv: {
        width: '100%',
        backgroundColor: "#F3F3F3"
    }
}));

export function Interests() {
    const classes = useStyles();

    // React hook used for description state.
    const [index, setIndex] = useState(4);

    const defaultDesc = ""
    const defaultIndex = 4

    // Convenience objects for description transitions.
    const [descriptions] = useState([
        { desc: interestsData[0].description, id: 0 },
        { desc: interestsData[1].description, id: 1 },
        { desc: interestsData[2].description, id: 2 },
        { desc: interestsData[3].description, id: 3 },
        { desc: defaultDesc, id: defaultIndex },
    ]);

    // Transition from reactSpring for description transitions.
    const fadingTextPropsTransition = useTransition(descriptions[index], item => item.id, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 500 },
    });

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 970px)'
    })

    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-width: 970px)'
    })

    return (
        <div>
            {isDesktopOrLaptop && <>
                <div className={classes.bigDiv}>
                    <Container>
                        {/* Title */}
                        {isDesktopOrLaptop &&
                            <Box paddingTop="50px" marginTop="50px" marginBottom="50px">
                                <h1> <strong>─── In my free time, I <em>enjoy</em>... ───</strong> </h1>
                            </Box>
                        }

                        {isTabletOrMobileDevice &&
                            <Box paddingTop="50px" marginTop="50px" marginBottom="50px">
                                <h1> <strong>In my free time, I <em>enjoy</em>...</strong> </h1>
                            </Box>
                        }

                        <Grid container spacing={1}>
                            {
                                /* Display grid in one row of 4 */
                                [...Array(interestsData.length).keys()].map((index) =>
                                    <Grid container item xs={6} sm={6} md={3} lg={3} key={interestsData[index].title}>
                                        <div id="interest">

                                            {/* Image displayed when mouse is not hovering over space. */}
                                            <img
                                                className="bottom"
                                                key={interestsData[index].title}
                                                src={interestsData[index].imgAlt}
                                                alt={interestsData[index].title}
                                            />

                                            {/* Image displayed when mouse is hovering over space. */}
                                            <img
                                                className="top"
                                                key={interestsData[index].altKey}
                                                src={interestsData[index].img}
                                                alt={interestsData[index].title}
                                                onMouseEnter={(event) => { setIndex(index); }}
                                                onMouseOut={(event) => { setIndex(defaultIndex) }}
                                            />

                                        </div>
                                    </Grid>
                                )
                            }
                        </Grid>

                        {/* Description text */}
                        <Box p={5}>
                            {fadingTextPropsTransition.map(({ item, props, key }) => (
                                <animated.div key={key} style={{ ...props, position: 'absolute', paddingRight: "100px" }}>
                                    <Typography variant="h6" align="center" style={{ marginTop: "50px", marginBottom: "100px" }}>
                                        {item.desc}
                                    </Typography>
                                </animated.div>
                            ))}
                        </Box>


                    </Container>
                </div>
            </>}
            {isTabletOrMobileDevice && <>
                <div className={classes.smallDiv}>
                    <Container>
                        {/* Title */}
                        {isDesktopOrLaptop &&
                            <Box paddingTop="50px" marginTop="50px" marginBottom="50px">
                                <h1> <strong>─── In my free time, I <em>enjoy</em>... ───</strong> </h1>
                            </Box>
                        }

                        {isTabletOrMobileDevice &&
                            <Box paddingTop="50px" marginTop="50px" marginBottom="50px">
                                <h1> <strong>In my free time, I <em>enjoy</em>...</strong> </h1>
                            </Box>
                        }

                        <Grid container spacing={1}>
                            {
                                /* Display grid in one row of 4 */
                                [...Array(interestsData.length).keys()].map((index) =>
                                    <Grid container item xs={6} sm={6} md={3} lg={3} key={interestsData[index].title}>
                                        <div id="interestMobile">

                                            {/* Image displayed when mouse is not hovering over space. */}
                                            <img
                                                className="bottom"
                                                key={interestsData[index].title}
                                                src={interestsData[index].imgAlt}
                                                alt={interestsData[index].title}
                                            />

                                            {/* Image displayed when mouse is hovering over space. */}
                                            <img
                                                className="top"
                                                key={interestsData[index].altKey}
                                                src={interestsData[index].img}
                                                alt={interestsData[index].title}
                                                onClick={(event) => { setIndex(index); }}
                                            />

                                        </div>
                                    </Grid>
                                )
                            }
                        </Grid>

                        {/* Description text */}
                        <Box m={5}>
                            {fadingTextPropsTransition.map(({ item, props, key }) => (
                                <animated.div key={key} style={{ ...props}}>
                                    <Typography variant="h6" align="center" style={{ marginTop: "50px" }}>
                                        {item.desc}
                                    </Typography>
                                </animated.div>
                            ))}
                        </Box>

                        <Box height="50px"></Box>

                    </Container>
                </div>
            </>}

        </div>
    );
}
