/* Import images */
import img0 from './media/food.png';
import img0Alt from './media/foodAlt.png';
import img1 from './media/basketball.jpg';
import img1Alt from './media/basketballAlt.png';
import img2 from './media/movies.jpg';
import img2Alt from './media/moviesAlt.png';
import img3 from './media/charlie.png';
import img3Alt from './media/charlieAlt.png';

/* Map to data */
const interestsData = [
    {
    img: img0,
    imgAlt: img0Alt,
    title: 'Food',
    altKey: 'Food Alt',
    description: "My favorite thing about Houston is the food. I really enjoy Tex-Mex and exploring places in Chinatown, but my go-to spot is"+ 
    " Aga's, an Indian-Pakistani restaurant. The tikka masala and biryani from there are unmatched. I also really like boba, crawfish, and Mediterranean food." +
    " Fadi's specifically has some great vegetarian options. No matter where I end up, the food from Houston will keep me coming back.",
    },
    {
    img: img1,
    imgAlt: img1Alt,
    title: 'Basketball',
    altKey: 'Basketball Alt',
    description: "If the Houston Rockets are playing, I'm probably watching. I've been a big basketball fan for about 10 years and am always ready to have an NBA debate, especially about the infamous Game 7 of the 2018 Rockets-Warriors series." +
    " I'll never forget attending the NBA All-Star Jam Session in 2013, back when Houston hosted the All-Star game. I didn't realize how famous they were at the time, but I got autographs from Chris Mullin and Robert Horry aka Big Shot Bob. " +
    " I try to attend 2-3 Rockets games every season, and my goal is to eventually have seen all 30 teams play (I'm currently at 15).",
    },
    {
    img: img2,
    imgAlt: img2Alt,
    title: 'Movies',
    altKey: 'Movies Alt',
    description: "I really started to get interested in watching movies and tv shows back in high school. My favorite movies are the Matrix, Interstellar, and The Shawshank Redemption, and my favorite tv shows are Breaking Bad, Westworld, and Game of Thrones." +
    " I make an effort to go the movies every month or two to experience all of the improvements in audio and visual technology. I especially enjoy thrillers and movies with unexpected plot twists. I'm currently going down IMDB's top 150 movies list!",
    },
    {
    img: img3,
    imgAlt: img3Alt,
    title: 'Charlie',
    altKey: 'Charlie Alt',
    description: "Meet Charlie, my hyper, energetic beagle. He's almost two years old and loves to bathe in the Houston sun." +
    " Our favorite game is fetch, and Charlie never fails to amaze me with how athletic he is.",
    }
]

export default interestsData;
