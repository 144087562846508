import "./Projects.css";

import { Box, Container, Grid, Typography } from '@material-ui/core';

import Avatar from '@material-ui/core/Avatar';
/* Import dialog components */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
/* Import icons */
import HelpIcon from '@material-ui/icons/Help';
import ImageCarouselBot from './ImageCarouselBot.js';
import ImageCarouselKempner from './ImageCarouselKempner.js';
import ImageCarouselMenu from './ImageCarouselMenu.js';
import InfoIcon from '@material-ui/icons/Info';
/* Import list components */
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import Slide from '@material-ui/core/Slide';
import StarIcon from '@material-ui/icons/Star';
import { makeStyles } from '@material-ui/core/styles';
/* Import project data */
import projectData from './projectData.js';
import { useMediaQuery } from 'react-responsive';
import { withStyles } from '@material-ui/core/styles';
/* Import colors */
import yellow from '@material-ui/core/colors/yellow';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '25px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  grid: {
    marginTop: '100px'
  },
  blackWhite: {
    color: theme.palette.grey[100],
    backgroundColor: theme.palette.grey[900],
  },
  blackYellow: {
    color: yellow[600],
    backgroundColor: theme.palette.grey[900],
  },
  indiaImg: {
    width: '135px',
    height: '85px'
  },
  indiaAnchor: {
    marginLeft: theme.spacing(3),
    width: '135px',
    height: '85px'
  },
  maliImg: {
    width: '128px',
    height: '85px'
  },
  maliAnchor: {
    width: '128px',
    height: '85px'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export function Projects() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 1224px)'
  })

  return (
    <Container>
      {/* Title */}
      {isDesktopOrLaptop &&
        <Box paddingTop="50px" marginTop="50px">
          <h1> <strong>─── Outside of class, I've been <em>developing</em>... ───</strong> </h1>
        </Box>
      }

      {isTabletOrMobileDevice &&
        <Box paddingTop="50px" marginTop="50px">
          <h1> <strong>Outside of class, I've been <em>developing</em>...</strong> </h1>
        </Box>
      }

      {/* Project grid */}

      <Grid id="projectsGrid" container spacing={0} >

        {/* Row 1 */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify="center" spacing={3} >
            <Grid item key={projectData[0].title} onClick={(event) => {
              setIndex(0);
              handleClickOpen();
            }}>
              <div id="project0">

                {/* Image displayed when mouse is not hovering over space. */}
                <img
                  className="bottom"
                  key={projectData[0].title}
                  src={projectData[0].imgAlt}
                  alt={projectData[0].title}
                />

                {/* Image displayed when mouse is hovering over space. */}
                <img
                  className="top"
                  key={projectData[0].altKey}
                  src={projectData[0].img}
                  alt={projectData[0].title}
                />

              </div>
            </Grid>

            <Grid item key={projectData[1].title} onClick={(event) => {
              setIndex(1);
              handleClickOpen();
            }}>
              <div id="project1">

                {/* Image displayed when mouse is not hovering over space. */}
                <img
                  className="bottom"
                  key={projectData[1].title}
                  src={projectData[1].imgAlt}
                  alt={projectData[1].title}
                />

                {/* Image displayed when mouse is hovering over space. */}
                <img
                  className="top"
                  key={projectData[1].altKey}
                  src={projectData[1].img}
                  alt={projectData[1].title}
                />

              </div>
            </Grid>
          </Grid>
        </Grid>

        {/* Row 2 */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify="center" spacing={3}>
            <Grid item key={projectData[2].title} onClick={(event) => {
              setIndex(2);
              handleClickOpen();
            }}>
              <div id="project2">

                {/* Image displayed when mouse is not hovering over space. */}
                <img
                  className="bottom"
                  key={projectData[2].title}
                  src={projectData[2].imgAlt}
                  alt={projectData[2].title}
                />

                {/* Image displayed when mouse is hovering over space. */}
                <img
                  className="top"
                  key={projectData[2].altKey}
                  src={projectData[2].img}
                  alt={projectData[2].title}
                />

              </div>
            </Grid>

            <Grid item key={projectData[3].title} onClick={(event) => {
              setIndex(3);
              handleClickOpen();
            }}>
              <div id="project3">

                {/* Image displayed when mouse is not hovering over space. */}
                <img
                  className="bottom"
                  key={projectData[3].title}
                  src={projectData[3].imgAlt}
                  alt={projectData[3].title}
                />

                {/* Image displayed when mouse is hovering over space. */}
                <img
                  className="top"
                  key={projectData[3].altKey}
                  src={projectData[3].img}
                  alt={projectData[3].title}
                />

              </div>
            </Grid>
          </Grid>
        </Grid>

        {/* Row 3 */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container justify="center" spacing={3}>
            <Grid item key={projectData[4].title} onClick={(event) => {
              setIndex(4);
              handleClickOpen();
            }}>
              <div id="project4">

                {/* Image displayed when mouse is not hovering over space. */}
                <img
                  className="bottom"
                  key={projectData[4].title}
                  src={projectData[4].imgAlt}
                  alt={projectData[4].title}
                />

                {/* Image displayed when mouse is hovering over space. */}
                <img
                  className="top"
                  key={projectData[4].altKey}
                  src={projectData[4].img}
                  alt={projectData[4].title}
                />

              </div>
            </Grid>

            <Grid item key={projectData[5].title} onClick={(event) => {
              setIndex(5);
              handleClickOpen();
            }}>
              <div id="project5">

                {/* Image displayed when mouse is not hovering over space. */}
                <img
                  className="bottom"
                  key={projectData[5].title}
                  src={projectData[5].imgAlt}
                  alt={projectData[5].title}
                />

                {/* Image displayed when mouse is hovering over space. */}
                <img
                  className="top"
                  key={projectData[5].altKey}
                  src={projectData[5].img}
                  alt={projectData[5].title}
                />

              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>


      {/* Each dialog box */}
      <Dialog onClose={handleClose} TransitionComponent={Transition} aria-labelledby="customized-dialog-title" open={open}>

        {/* Top section */}
        <MuiDialogTitle id="customized-dialog-title" onClose={handleClose}>

          {/* Project icon and name */}
          <Box display="flex" flexDirection="row">

            <Box paddingTop={.5} marginRight={3.75} marginLeft={1.5}>
              {projectData[index].icon}
            </Box>

            <Typography variant="h5">
              {projectData[index].title}
            </Typography>

          </Box>

        </MuiDialogTitle>

        <DialogContent dividers>

          <List>

            <ListItem>
              <ListItemAvatar>
                <Avatar className={classes.blackWhite}>
                  <InfoIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Details" secondary={projectData[index].details} />
            </ListItem>

            <Divider variant="inset" component="li" />

            <ListItem>
              <ListItemAvatar>
                <Avatar className={classes.blackYellow}>
                  <StarIcon />
                </Avatar>
              </ListItemAvatar>
              {(index === 0 || index === 1) ? <ListItemText primary="Skills I'm learning" secondary={projectData[index].lessons} /> : <ListItemText primary="Skills I learned" secondary={projectData[index].lessons} />}
            </ListItem>

            <Divider variant="inset" component="li" />

            {index !== 3 &&

              <ListItem>
                <ListItemAvatar>
                  <Avatar className={classes.blackWhite}>
                    <HelpIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Additional details" secondary={projectData[index].additionalDetails} />
              </ListItem>

            }

            {
              index === 0 &&

              <Box display="flex" flexDirection="row" marginLeft={'72px'}>
                <a
                  className={classes.maliAnchor}
                  href="https://comp410s21apimanagement.azure-api.net/"
                  target="_blank"
                  rel="noopener noreferrer">
                  Interlocal
                </a>

              </Box>

            }

            {
              index === 1 &&

              <Box display="flex" flexDirection="row" marginLeft={'72px'}>
                <a
                  className={classes.maliAnchor}
                  href="https://en.wikipedia.org/wiki/Education_in_Mali"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    className={classes.maliImg}
                    key={"mali"}
                    src={projectData[1].additionalImages[0]}
                    alt={"Mali"}
                  />
                </a>

                <a
                  className={classes.indiaAnchor}
                  href="https://en.wikipedia.org/wiki/Debt_bondage_in_India"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    className={classes.indiaImg}
                    key={"india"}
                    src={projectData[1].additionalImages[1]}
                    alt={"India"}
                  />
                </a>

              </Box>

            }

            {
              index === 2 &&

              <ImageCarouselMenu></ImageCarouselMenu>

            }

            {
              index === 4 &&

              <ImageCarouselBot></ImageCarouselBot>
            }

            {
              index === 5 &&

              <ImageCarouselKempner></ImageCarouselKempner>
            }


          </List>

        </DialogContent>

        <DialogActions>

          <Button autoFocus onClick={handleClose} color="primary">
            Continue
          </Button>

        </DialogActions>

      </Dialog>

    </Container>
  );
}
